<template>
  <CSidebar
      fixed
      :minimize="minimize"
      :show="show"
      @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <img :src="'/img/logo.png'" class="mw-100">
    </CSidebarBrand>
    <nav-render flat :content-to-render="$options.nav"/>
    <CSidebarMinimizer
        class="d-md-down-none"
        @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav'
import NavRender from "@/containers/NavRender";
import NavItem from "@/containers/NavItem";

export default {
  name: 'TheSidebar',
  components: {NavItem, NavRender},
  nav,
  computed: {
    logoPath() {
      return '/img/logo.png'
    },
    show() {
      return this.$store.state.sidebarShow
    },
    minimize() {
      return this.$store.state.sidebarMinimize
    }
  }
}
</script>
<style scoped>
</style>