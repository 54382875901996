<template>
  <CDropdown
      inNav
      class="c-header-nav-items"
      placement="bottom-end"
      add-menu-classes="py-0 rounded-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <span class="d-none d-md-inline">
            {{ $auth.user() ? $auth.user().name : ''}}
        </span>
      </CHeaderNavLink>
    </template>
    <CDropdownItem @click="$router.push({name:'Profile'})" class="px-3 text-uppercase font-weight-medium">
      <i class="fas fa-user  mr-2"></i>
      Profile
    </CDropdownItem>
    <CDropdownItem @click="logout" class="px-3 text-uppercase  font-weight-medium">
      <i class="fas fa-sign-out-alt mr-2"></i>
      Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccnt',
  data() {
    return {
      itemsCount: 0,
    }
  },
  mounted() {
  },
  methods: {
    logout() {
      this.$auth
          .logout({
            makeRequest: true,
            redirect: {name: 'Login'},
          });
    },
  }
}
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>